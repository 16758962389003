import { cx } from '@emotion/css'
import { FormattedMessage } from 'react-intl'
import MapPinIcon from '../../../atoms/icons/map-pin-icon'
import SocialLinks from '../social-links'
import StorelocatorLink from '../storelocator-link'
import { ChangeCountryLanguageStyled, MiddleBottomWrapper } from './middle-bottom-area.styled'

const MiddleBottomArea = (className) => {
	return (
		<MiddleBottomWrapper className={cx('MiddleBottomArea-root', className)}>
			<ChangeCountryLanguageStyled
				className="change-country-language-trigger-footer"
				showArrowDown
				showCountryCode
				showCurrencySymbol={false}
				showFlag={false}
			/>

			<StorelocatorLink
				className="footer-storelocator-link"
				buttonProps={{
					StartIcon: MapPinIcon,
					variant: 'ghost',
					iconProps: { fontSize: 'middle' },
					label: <FormattedMessage id={'footer_store_locator_link_label'} />,
				}}
			/>
			<SocialLinks
				buttonProps={{ component: 'a', variant: 'icon', color: 'primary' }}
				iconProps={{ fontSize: 'middle' }}
				className="BHFooterSocialLinks"
			/>
		</MiddleBottomWrapper>
	)
}

export default MiddleBottomArea
