import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	min-height: unset;
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	justify-content: center;
	text-align: center;
	a {
		color: ${({ theme }) => theme.palette.footer.color};
	}
	padding: ${({ theme }) => theme.spacing(2)};

	${({ theme }) => theme.breakpoints.up('sm')} {
		padding: ${({ theme }) => theme.spacing(0)};
		> div {
			padding: ${({ theme }) => theme.spacing(1.5)} 86px;
		}
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		justify-content: flex-start;
		> div {
			padding: ${({ theme }) => theme.spacing(1.5)} 176px;
		}
	}
`

export { BottomAreaWrapper }
