import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};

	display: flex;
	flex-direction: column;

	${({ theme }) => theme.breakpoints.up('sm')} {
		padding: 45px 86px 25px;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		padding: 45px 176px 25px;
	}

	& .BHFooterMenus {
		border-top: 0;
		padding: 0px 24px;
		${({ theme }) => theme.breakpoints.up('md')} {
			border-top: none;
			padding: 0;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border: none;
			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};
			}
		}
	}
	& .BHNewsletter {
		padding: ${({ theme }) => theme.spacing(3.5, 3, 0)};

		.MuiInputBase-root {
			border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
			border-radius: 0;
		}
		.MuiInputBase-input {
			padding: ${({ theme }) => theme.spacing(1, 0)};
		}
		.MuiButtonBase-root {
			right: 0;
			top: 0;
			width: 50px;
			height: 34px;
			padding: 0;
			border-radius: 0px;
			.MuiSvgIcon-root {
				font-size: ${({ theme }) => theme.typography.pxToRem(20)};
			}
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: 0;
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	width: 100%;
	${({ theme }) => theme.breakpoints.up('md')} {
		order: 2;
		max-width: 305px;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
